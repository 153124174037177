import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../SalesOrder.css';
import CustomerAddress from './CustomerAddress';
import CustomerAddressChangeMenuContainer from '../containers/CustomerAddressChangeMenuContainer';
import AddressInputModal from '../../Account/Presenter/AddressInputModal';
import { getCountryList } from '../../../action/Country';
import { getStateList } from '../../../action/State';
import { insertAddressRequest } from '../../../action/account';
import { setSelectedShippingAddress } from '../../../action/customer';
import CustomerContact from './CustomerContact';
import { Checkbox, Grid, Header, Input } from 'semantic-ui-react';
import DropShipContactInputModal from './DropShipContactInputModal';
import { insertContact } from '../../../action/contact';
import CustomerDropShipContactChangeMenuContainer from '../containers/CustomerDropShipContactChangeMenuContainer';

class CustomerDetailWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingNewAddress: false,
      newAddress: {
        country: '',
        countryName: '',
        state: '',
        city: '',
        address: '',
        postal_code: '',
        type: '',
        account_id: this.props.selectedCustomer.AccountID,
        selectedCountry: {},
      },
      dropShipContact: this.props.dropShipContact ?? {
        name: '',
        email: '',
        phone: '',
        mobile_phone: '',
        note: '',
        title: '',
      },
      validationError: {
        country: false,
        address: false,
      },
      showEditDropShipContactSelector: false,
      showAddDropShipContactPopup: false,
      dropship_contact_id: null,
      isSubmittingDropShipContact: false,
    };
  }

  componentDidMount() {
    this.props.getCountryList();
    this.props.getStateList();
    this.updateAccountId();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedCustomer.AccountID !==
      this.props.selectedCustomer.AccountID
    ) {
      this.updateAccountId();
    }
  }

  updateAccountId = () => {
    this.setState((prevState) => ({
      newAddress: {
        ...prevState.newAddress,
        account_id: this.props.selectedCustomer.AccountID,
      },
    }));
  };

  onAddressSelect = (address) => {
    this.props.patchAddressRequest(
      {
        active: 1,
      },
      address.id
    );
    this.props.setSelectedAddress(address);
    this.props.closeAddressSelector();
  };

  handleAddNewAddress = () => {
    this.setState({ isAddingNewAddress: true });
  };

  handleCancelNewAddress = () => {
    this.setState({ isAddingNewAddress: false });
  };

  onNewAddressChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      newAddress: {
        ...prevState.newAddress,
        [name]: value,
      },
      validationError: {
        ...prevState.validationError,
        address: name === 'address' ? false : prevState.validationError.address,
      },
    }));
  };

  onNewCountryChange = (e) => {
    this.setState((prevState) => ({
      newAddress: {
        ...prevState.newAddress,
        selectedCountry: e.target.value,
        country: e.target.value.CountryID,
        countryName: e.target.value.Name,
      },
      validationError: {
        ...prevState.validationError,
        country: false,
      },
    }));
  };

  onNewTypeChange = (types) => {
    this.setState((prevState) => ({
      newAddress: { ...prevState.newAddress, type: types },
    }));
  };

  onNewAddressSubmit = () => {
    this.props.setSelectedShippingAddress(this.state.newAddress);

    // address만 새롭게 추가하는 경우 shipping, billing 모두 default로 설정하지는 않음
    this.props
      .insertAddressRequest({
        ...this.state.newAddress,
        is_default_shipping: false,
        is_default_billing: false,
      })
      .then((response) => {
        this.props.fields.ship_to.input.onChange(response.newAddressId);
      });
    this.setState({
      isAddingNewAddress: false,
      newAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        postal_code: '',
        type: '',
        account_id: this.props.selectedCustomer.AccountID,
        selectedCountry: {},
      },
    });
  };

  toggleDropShipContactPopup = () => {
    this.setState((prevState) => ({
      showDropShipContactPopup: !prevState.showDropShipContactPopup,
    }));
  };

  closeDropShipContactPopup = () => {
    this.setState({
      showDropShipContactPopup: false,
    });
  };

  onDropShipContactChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      dropShipContact: {
        ...this.state.dropShipContact,
        [name]: value,
      },
    });
  };

  onDropShipContactSubmit = (contact) => {
    // 새로운 contact 만들기
    if (this.state.isSubmittingDropShipContact) return;

    this.setState({
      isSubmittingDropShipContact: true,
    });

    this.props
      .insertContact({
        ...contact,
        account_id: this.props.selectedCustomer.AccountID,
      })
      .then((res) => {
        this.setState({
          dropship_contact_id: res.newContactId,
          dropShipContact: {
            name: contact.name,
            email: contact.email,
            phone: contact.phone,
            mobile_phone: contact.mobile_phone,
            note: contact.note,
          },
          showAddDropShipContactPopup: false,
        });
        this.props.fields.DropShipContactId.input.onChange(res.newContactId);
      })
      .finally(() => {
        this.setState({
          isSubmittingDropShipContact: false,
        });
      });
  };

  onContactSelect = (contact) => {
    // 기존 cotact 중 select
    this.props.fields.DropShipContactId.input.onChange(contact.id);

    this.setState({
      dropship_contact_id: contact.id,
      dropShipContact: {
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        mobile_phone: contact.mobile_phone,
        note: contact.note,
      },
      showEditDropShipContactSelector: false,
    });
  };

  render() {
    const {
      selectedCustomer,
      isCustomerAddressSelectorOpen,
      openCustomerAddressSelector,
      closeAddressSelector,
      header,
      selectedAddress,
      fields,
      type,
      countryList,
      stateList,
    } = this.props;

    const {
      isAddingNewAddress,
      newAddress,
      validationError,
      showAddDropShipContactPopup,
      showEditDropShipContactSelector,
      dropShipContact,
    } = this.state;

    return (
      <div className="CustomerDetail">
        <div style={{ flex: '2 1' }}>
          <CustomerAddress
            {...selectedAddress}
            header={header}
            customerName={selectedCustomer.Name}
            isEditable={selectedCustomer.AccountID && type === 'shipping'}
            isAddable={selectedCustomer.AccountID && type === 'shipping'}
            editOnClick={openCustomerAddressSelector}
            addOnClick={this.handleAddNewAddress}
          />
          {/* dropship contact */}
          {type === 'shipping' && (
            <div
              style={{
                position: 'relative',
              }}
            >
              <CustomerContact
                header="DropShip Contact"
                {...dropShipContact}
                isEditable={!!selectedCustomer.AccountID}
                isAddable={!!selectedCustomer.AccountID}
                isDeletable={!!selectedCustomer.AccountID && !!dropShipContact}
                editOnClick={() => {
                  this.setState({
                    showEditDropShipContactSelector: true,
                  });
                }}
                addOnClick={() => {
                  this.setState({
                    showAddDropShipContactPopup: true,
                  });
                }}
                deleteOnClick={() => {
                  this.props.fields.DropShipContactId.input.onChange(null);
                  this.setState({
                    dropShipContact: null,
                    dropship_contact_id: null,
                  });
                }}
              />
              {showEditDropShipContactSelector &&
                selectedCustomer.AccountID && (
                  <CustomerDropShipContactChangeMenuContainer
                    fields={fields}
                    AccountID={selectedCustomer.AccountID}
                    closeCustomerDropShipContactSelector={() => {
                      this.setState({
                        showEditDropShipContactSelector: false,
                      });
                    }}
                    onContactSelect={this.onContactSelect}
                  />
                )}
            </div>
          )}
          {/* 새로운 DropShip Contact 추가 팝업 */}
          {showAddDropShipContactPopup && (
            <DropShipContactInputModal
              onDropShipContactSubmit={this.onDropShipContactSubmit}
              onCancel={() => {
                this.setState({
                  showAddDropShipContactPopup: false,
                });
              }}
            />
          )}
          {isCustomerAddressSelectorOpen && selectedCustomer.Name ? (
            <CustomerAddressChangeMenuContainer
              fields={fields}
              AccountID={selectedCustomer.AccountID}
              closeCustomerShppingAddressSelector={closeAddressSelector}
              onAddressSelect={this.onAddressSelect}
            />
          ) : null}
          {isAddingNewAddress && (
            <AddressInputModal
              newAddress={newAddress}
              validationError={validationError}
              countryList={countryList}
              stateList={stateList}
              onNewAddressChange={this.onNewAddressChange}
              onNewCountryChange={this.onNewCountryChange}
              onNewTypeChange={this.onNewTypeChange}
              onNewAddressSubmit={this.onNewAddressSubmit}
              onCancel={this.handleCancelNewAddress}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  countryList: state.countryList,
  stateList: state.stateList,
  selectedShippingAddress: state.selectedAddress.shippingAddress,
});

const mapDispatchToProps = (dispatch) => ({
  getCountryList: bindActionCreators(getCountryList, dispatch),
  getStateList: bindActionCreators(getStateList, dispatch),
  insertAddressRequest: bindActionCreators(insertAddressRequest, dispatch),
  setSelectedShippingAddress: bindActionCreators(
    setSelectedShippingAddress,
    dispatch
  ),
  insertContact: bindActionCreators(insertContact, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDetailWrapper);
