import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';

const propTypes = {
  productOrder: PropTypes.shape({
    id: PropTypes.number,
    product: PropTypes.shape({
      ProductCode: PropTypes.string,
      ProductId: PropTypes.number,
      ModelT: PropTypes.string,
      Model: PropTypes.string,
      Color: PropTypes.string,
      Voltage: PropTypes.string,
      HDD: PropTypes.string,
      SSD: PropTypes.string,
      USDRetail: PropTypes.number,
      KRWRetail: PropTypes.number,
      ModelId: PropTypes.number,
    }).isRequired,
    productModel: PropTypes.shape({
      ModelId: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      Description: PropTypes.string,
      HS_CODE: PropTypes.string,
    }),
    quantity: PropTypes.number.isRequired,
    discount: PropTypes.number.isRequired,
  }).isRequired,
  currency: PropTypes.string,
  salesOrderType: PropTypes.number.isRequired,
};

const defaultProps = {};

class ProductOrderListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 0,
      discount: 0,
      fobPrice: 0,
      amount: 0,
      lastAmount: 0,
      isCustomAmount: false,
    };
    this.setRate = this.setRate.bind(this);
    this.setAmount = this.setAmount.bind(this);
    this.toIntegerHelper = this.toIntegerHelper.bind(this);
    this.calculateDiscount = this.calculateDiscount.bind(this);
    this.discountInputOnChangeHandler =
      this.discountInputOnChangeHandler.bind(this);
    this.quantityInputOnChangeHandler =
      this.quantityInputOnChangeHandler.bind(this);
    this.fobPriceInputOnChangeHandler =
      this.fobPriceInputOnChangeHandler.bind(this);
    this.amountInputOnChangeHandler =
      this.amountInputOnChangeHandler.bind(this);
    this.numberFormatOnChangeHandler =
      this.numberFormatOnChangeHandler.bind(this);
  }

  numberFormatOnChangeHandler(value, noCurrency) {
    if (typeof value !== 'string') {
      value = String(value);
    }

    let returnValue;
    if (noCurrency) {
      returnValue = value.replace(/,/gi, '');
    } else {
      returnValue =
        value.charAt(0) === '$' || value.charAt(0) === '₩'
          ? value.slice(1).replace(/,/gi, '')
          : value.replace(/,/gi, '');
    }

    return returnValue;
  }

  toIntegerHelper = (num) => {
    if (!num) return 0;

    let valueString = num.toString();
    if (!valueString.includes('.')) return 0;

    let value = valueString.split('.')[1];
    return value ? String(value).length : 0;
  };

  calculateDiscount = (discount) => {
    if (!discount || isNaN(discount)) {
      return 1;
    }

    try {
      var T1 = Number('1e' + this.toIntegerHelper(discount));
      var T2 = Number(
        '1e' + this.toIntegerHelper((100 * T1 - discount * T1) / T1)
      );
      var returnDiscount =
        Math.floor(((100 * T1 - discount * T1) / T1) * T2) / (100 * T2);

      return isNaN(returnDiscount) ? 1 : returnDiscount;
    } catch (error) {
      return 1;
    }
  };

  setRate(from) {
    if (from.currency.trim() == 'USD')
      return from.productOrder.product.USDRetail;
    else if (from.currency.trim() == 'KRW')
      return from.productOrder.product.KRWRetail;
    else return from.productOrder.product.USDRetail;
  }

  setAmount(rate, quantity, discount) {
    if (!rate || !quantity || isNaN(rate) || isNaN(quantity)) {
      return 0;
    }

    let amount = rate * quantity * this.calculateDiscount(discount);

    if (isNaN(amount)) {
      return 0;
    }

    if (this.props.currency.trim() === 'USD') return Number(amount.toFixed(2));
    if (this.props.currency.trim() === 'KRW') return Number(amount.toFixed(0));
    return Number(amount.toFixed(2));
  }

  discountInputOnChangeHandler(event) {
    let value = this.numberFormatOnChangeHandler(event.target.value, true);
    this.setState({
      discount: value,
      lastAmount: this.state.amount,
      amount: this.setAmount(
        this.setRate(this.props),
        this.state.quantity,
        value
      ),
    });
    this.props.onDiscountChange(this.props.productOrder.id, value);
  }

  quantityInputOnChangeHandler(event) {
    let value = this.numberFormatOnChangeHandler(event.target.value, true);
    value = Number(value); // Convert to number
    if (this.props.salesOrderType === 8 && value > 0) {
      value = -value; // Make sure it's negative for salesOrderType 8
    }
    this.setState({
      quantity: value,
      lastAmount: this.state.amount,
      amount: this.setAmount(
        this.setRate(this.props),
        value,
        this.state.discount
      ),
    });
    this.props.onQuantityChange(this.props.productOrder.id, value);
  }

  fobPriceInputOnChangeHandler(event) {
    let value = this.numberFormatOnChangeHandler(event.target.value, false);
    value = value === '' || isNaN(value) ? 0 : Number(value);

    this.setState({
      fobPrice: value,
      isCustomAmount: true,
    });

    const quantity = this.state.quantity || 0;
    const amount = value * quantity;

    this.amountInputOnChangeHandler({
      target: {
        value:
          this.props.currency.trim() === 'USD'
            ? amount.toFixed(2)
            : amount.toFixed(0),
      },
    });
  }

  amountInputOnChangeHandler(event) {
    let value = this.numberFormatOnChangeHandler(event.target.value, false);
    value = value === '' || isNaN(value) ? 0 : Number(value);

    this.setState({
      lastAmount: this.state.amount,
      amount: value,
      isCustomAmount: true,
    });

    if (this.state.quantity === 0 || !this.state.quantity) {
      this.props.onDiscountChange(this.props.productOrder.id, 0);
      return;
    }

    const rate = this.setRate(this.props);
    const discount =
      rate && this.state.quantity
        ? 100 - (value / (rate * this.state.quantity)) * 100
        : 0;

    this.props.onDiscountChange(this.props.productOrder.id, discount);
  }

  componentDidMount() {
    const quantity =
      this.props.salesOrderType === 8
        ? -Math.abs(this.props.productOrder.quantity)
        : this.props.productOrder.quantity;
    const amount = this.setAmount(
      this.setRate(this.props),
      quantity,
      this.props.productOrder.discount
    );
    const fobPrice = Math.abs(amount / quantity); // Use absolute value to avoid negative FOB price
    this.setState({
      discount: this.props.productOrder.discount,
      rate: this.setRate(this.props),
      quantity,
      amount,
      fobPrice,
    });
  }

  componentWillReceiveProps(nextProps) {
    const quantity =
      nextProps.salesOrderType === 8
        ? -Math.abs(nextProps.productOrder.quantity)
        : nextProps.productOrder.quantity;
    const amount = this.setAmount(
      this.setRate(nextProps),
      quantity,
      nextProps.productOrder.discount
    );
    const fobPrice = Math.abs(amount / quantity); // Use absolute value to avoid negative FOB price
    this.setState({
      discount: nextProps.productOrder.discount,
      quantity,
      rate: this.setRate(nextProps),
      fobPrice,
      amount,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.isCustomAmount &&
      Number(nextState.amount) == Number(nextState.lastAmount) &&
      this.props.salesOrderType === nextProps.salesOrderType
    )
      return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.salesOrderType !== this.props.salesOrderType) {
      const quantity =
        this.props.salesOrderType === 8
          ? -Math.abs(this.state.quantity)
          : Math.abs(this.state.quantity);
      const amount = this.setAmount(
        this.setRate(this.props),
        quantity,
        this.state.discount
      );
      const fobPrice = Math.abs(amount / quantity);
      this.setState({
        quantity,
        amount,
        fobPrice,
      });
    }
  }

  render() {
    return (
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <p style={{ fontWeight: 900 }}>
              {this.props.productOrder.product.ProductCode}
            </p>
            {this.props.productOrder.product.Model.trim() == 'CS_Parts' ? (
              <p style={{ margin: 0, fontSize: '12px' }}>
                SKU:{' '}
                {this.props.productOrder.product.sku
                  ? this.props.productOrder.product.sku
                  : `${this.props.productOrder.product.Model.trim()}-${this.props.productOrder.product.Item != null ? this.props.productOrder.product.Item.trim() : null}`}
              </p>
            ) : (
              <p style={{ margin: 0, fontSize: '12px' }}>
                SKU:
                {this.props.productOrder.product.sku
                  ? this.props.productOrder.product.sku
                  : `${this.props.productOrder.product.Model.trim()}-${this.props.productOrder.product.Color.trim()}-${this.props.productOrder.product.Voltage.trim()}-${this.props.productOrder.product.HDD.trim()}-${this.props.productOrder.product.SSD.trim()}`}
              </p>
            )}
            <p style={{ margin: 0, fontSize: '12px' }}>
              {this.props.productOrder.productModel.Description == undefined
                ? ''
                : this.props.productOrder.productModel.Description.trim()}
              {this.props.productOrder.productModel.Description == undefined ||
              this.props.productOrder.productModel.HS_CODE == undefined
                ? ''
                : ' / '}
              {this.props.productOrder.productModel.HS_CODE == undefined
                ? ''
                : this.props.productOrder.productModel.HS_CODE.trim()}
            </p>
          </Table.Cell>
          <Table.Cell width={1}>
            <div class="ui input">
              <NumberFormat
                style={{ width: '60px', textAlign: 'right' }}
                value={this.state.quantity}
                thousandSeparator={true}
                onChange={this.quantityInputOnChangeHandler}
              />
              {this.props.productOrder.serials ? (
                <span
                  style={{
                    marginLeft: '10px',
                    lineHeight: '40px',
                  }}
                >
                  {' '}
                  /{' '}
                  {this.props.productOrder.serials.length ||
                    this.props.productOrder.orderCount}{' '}
                </span>
              ) : null}
            </div>
          </Table.Cell>
          <Table.Cell style={{ textAlign: 'end' }}>
            <NumberFormat
              value={this.setRate(this.props)}
              displayType={'text'}
              thousandSeparator={true}
              prefix={this.props.currency == 'KRW' ? '₩' : '$'}
            />
          </Table.Cell>
          <Table.Cell>
            <div class="ui input">
              <NumberFormat
                style={{ width: '100%', textAlign: 'right' }}
                value={this.state.discount}
                thousandSeparator={true}
                onChange={this.discountInputOnChangeHandler}
              />
            </div>
          </Table.Cell>
          <Table.Cell>
            <div class="ui input">
              <NumberFormat
                style={{ width: '100%', textAlign: 'right' }}
                value={this.state.fobPrice}
                thousandSeparator={true}
                decimalScale={2}
                prefix={this.props.currency == 'KRW' ? '₩' : '$'}
                onChange={this.fobPriceInputOnChangeHandler}
              />
            </div>
          </Table.Cell>
          <Table.Cell>
            <div class="ui input">
              <NumberFormat
                style={{ width: '100%', textAlign: 'right' }}
                value={this.state.amount}
                thousandSeparator={true}
                prefix={this.props.currency == 'KRW' ? '₩' : '$'}
                onChange={this.amountInputOnChangeHandler}
              />
            </div>
          </Table.Cell>
          <Table.Cell>
            <Button
              style={{ width: '100%', height: '100%' }}
              onClick={() =>
                this.props.onProductOrderDelete(this.props.productOrder.id)
              }
            >
              X
            </Button>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    );
  }
}

ProductOrderListItem.propTypes = propTypes;
ProductOrderListItem.defaultProps = defaultProps;

export default ProductOrderListItem;
