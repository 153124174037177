import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAddress,
  insertAddressRequest,
  updateAddressRequest,
} from '../../../action/account';
import AddressList from '../Presenter/AddressList';

class AddressListContainer extends Component {
  componentDidMount() {
    if (!this.props.addressList[this.props.AccountID]) {
      this.props.getAddress(this.props.AccountID);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.AccountID != nextProps.AccountID) return true;
    if (
      nextProps.addressList[this.props.AccountID] !=
      this.props.addressList[this.props.AccountID]
    ) {
      return true;
    }
    if (
      this.props.countryList != nextProps.countryList ||
      this.props.stateList != nextProps.stateList
    )
      return true;
    return false;
  }

  render() {
    return (
      <AddressList
        {...this.props}
        insertAddress={this.props.insertAddress}
        AddressList={this.props.addressList[this.props.AccountID]}
        countryList={this.props.countryList}
        stateList={this.props.stateList}
        AccountID={this.props.AccountID}
      />
    );
  }
}

export default connect(
  (state) => ({
    addressList: state.addressList,
    countryList: state.countryList,
    stateList: state.stateList,
  }),
  (dispatch) => ({
    getAddress: bindActionCreators(getAddress, dispatch),
    insertAddressRequest: bindActionCreators(insertAddressRequest, dispatch),
    updateAddressRequest: bindActionCreators(updateAddressRequest, dispatch),
  })
)(AddressListContainer);
